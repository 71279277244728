
class PaymentsController {

    displayFeeSheetForm: boolean = false;
    displayInsuranceSubMenu: boolean = false;

    static $inject = ['applicationObservable', 'Auth', 'USAuPairs'];
    constructor(applicationObservable, private Auth, private USAuPairs) {

        // If the AP is in US
        if (Auth.user.usAccessToken && Auth.user.usAccessToken.length) {
            this.USAuPairs.canAupairExtend({
                aupairId: this.Auth.user.usAuPairId
            })
                .$promise
                .then((result) => {
                    this.displayInsuranceSubMenu = result.data.isOkToShowInsuranceSubMenu;
                });
        }

        applicationObservable.subscribe(applicationInstance => {
            //fee sheet will not appear for paper references
            this.displayFeeSheetForm = !applicationInstance.isFeeSheetDocExists;
        });
    }        
}

angular
    .module('app')
    .controller('PaymentsController', PaymentsController);