import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class ReviewFactory extends DataAccessFactory {

	constructor() {
		super()
		this.server = 'review'

		this.initialize()
	}

	initialize() {
        let resources: Array<Resource> = [
            {
                name: 'StickyNote',
                url: 'review/note/:noteId',
                actions: {
                    save: {
                        method: 'POST'
                    },
                    complete: {
                        url: 'review/note/:noteId/completed',
                        method: 'POST'
                    },
                    dismissed: {
                        url: 'review/note/:noteId/dismissed',
                        method: 'POST'
                    }

                }
            },
            {
                name: 'InterviewerReview',
                url: 'review/application/:applicationId',
                actions: {
                    submitToAgency: {
                        url: 'review/application/:applicationId/submit-to-agency',
                        method: 'POST'
                    },
                    submitToOffice: {
                        url: 'review/application/:applicationId/submit-to-office',
                        method: 'POST'
                    }
                }
            },
            {
                name: 'ReviewApplication',
                url: 'review/application/:applicationId',
                actions: {
                    canAcceptApplication: {
                        url: 'review/application/:applicationId/canAcceptApplication',
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    },
                    startAndContinueReviewApplication: {
                        url: 'review/application/:applicationId/startAndContinueReviewApplication',
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    doneReviewApplication: {
                        url: 'review/application/:applicationId/reviewApplication',
                        method: 'DELETE',
                        params: { applicationId: '@applicationId' }
                    },
                    completeSection: {
                        url: 'review/application/:applicationId/reviewApplicationSection',
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    applicationStatusForApplicant: {
                        url: 'review/application/:applicationId/application-status-for-applicant',
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    },
                    canCancelApplication: {
                        url: 'review/application/:applicationId/canCancelApplication',
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    },
                    applicationStatusForApplicantByGroup: {
                        url: 'review/application/:applicationId/application-status-for-applicant/:groupId',
                        method: 'GET',
                        params: { applicationId: '@applicationId', groupId: '@groupId' }
                    },
                    canRePublishShare: {
                        url: 'review/application/:applicationId/canRePublishShare',
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationReviewItemsAny',
                url: 'review/application/:applicationId/checkIfAnyChangeMadeByApplicant',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApproveChanges',
                url: 'review/application/:applicationId/approveChange',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ReviewablePage',
                url: '',
                actions: {
                    applicationReviews: {
                        method: 'GET',
                        url: 'review/application/:applicationId/status/:referenceId/:itemId',
                        params: {
                            applicationId: 'applicationId',
                            referenceId: 'referenceId',
                            itemId: 'itemId'
                        }
                    },
                    updateStatus: {
                        method: 'POST',
                        url: 'review/application/:applicationId/status/:referenceId/:itemId',
                        params: {
                            applicationId: 'applicationId',
                            userId: 'userId'
                        }
                    },
                    applicationStatusForApplicant: {
                        method: 'GET',
                        url: 'review/application/:applicationId/application-status-for-applicant',
                        params: {
                            applicationId: 'applicationId'
                        }
                    },
                    applicationGroupErrors: {
                        method: 'GET',
                        isArray: true,
                        url: 'review/application/:applicationId/group/:groupId/erorrs',
                        params: {
                            applicationId: 'applicationId',
                            groupId: 'groupId'
                        }
                    },
                }
            }
		]

		this.process(resources)
	}
}

new ReviewFactory()
