import _ from 'lodash-es'
import { BusinessArea } from '../../../services/AuthService';
import { LegalOnHoldModalController } from './LegalOnHoldModalController'

export class ApplicantDataController {

    lastLogin: Date
    legalArea: boolean
    canRePublish: boolean = false;
    public alerts: Array<Object> = []
    public pendingRequest: boolean

    static $inject = ['$uibModal','dialogs', 'GdprStatus', 'User', 'Auth', '$scope', 'userLastLogin', 'userAgreements', 'gdprStatus', 'userStatus', 'applicantDataReplication', 'RePublishShare', 'applicationId','ReviewApplication']
    constructor(private $uibModal, private dialogs, private GdprStatus, private User, Auth, private $scope, userLastLogin, private userAgreements, private gdprStatus, private userStatus, private applicantDataReplication, private RePublishShare, private applicationId, private ReviewApplication) {
        this.lastLogin = userLastLogin.lastLogin;

        this.legalArea = Auth.isInBusinessArea(BusinessArea.LEGAL);

        if (Auth.user.isOfficer) {
            this.ReviewApplication
                .canRePublishShare({ applicationId: this.applicationId })
                .$promise
                .then((result) => {
                    if (result.error && result.error.length>0) {
                        this.canRePublish = false;//displayerroronclick
                    }
                });

            this.ReviewApplication.canRePublishShare({
                applicationId: this.applicationId
            }, {
                //do nothing
            }, (data) => {
                this.canRePublish = true;
            }, (data) => {
                if (data.status === 409) {
                    this.pendingRequest = false;
                    this.alerts = data.data;
                }
            })
        }

    }

    unlock() {
        this.User
            .unlock({ userId: this.userStatus.applicantUserId })
            .$promise
            .then((results) => {
                this.userStatus.isLockedOut = false;
            });
    }

    rePublishShare() {
        this.ReviewApplication.canRePublishShare({
                applicationId: this.applicationId
            }, {
                //do nothing
            }, (data) => {
            this.RePublishShare
                .publsh({ applicationId: this.applicationId })
                .$promise
                .then(() => {
                    //refresh grid data
                    //question after sometime it will update. It is coming from share. so we should write notify from push service
                    //this.$scope.$parent.$broadcast('applicationCommentsChange', null);
                });
            }, (data) => {
                if (data.status === 409) {
                    this.pendingRequest = false;
                    this.dialogs.error('Validation Error', data.data);
                }
            })
       
    }

    changeLegalOnHold() {
        return this.$uibModal
            .open({
                templateUrl: 'areas/application/applicantData/legalOnHoldModal.html',
                controller: LegalOnHoldModalController,
                controllerAs: '$ctrl',
                backdrop: false,
                resolve: {
                    gdprStatus: () => angular.copy(this.gdprStatus)
                }
            })
            .result
            .then((results) => {
                this.gdprStatus = results;
            });

    }
}


angular
    .module('app')
    .controller('ApplicantData', ApplicantDataController);

